<template>
  <v-card>
    <v-card-title>
      <menu-title title="File Uploads">
        <v-btn
          outlined
          @click="uploadDialog=true"
        >Upload file</v-btn>
      </menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          align="left"
        >
          <v-text-field
            outlined
            hide-details
            v-model="search"
            label="Search..."
            prepend-icon="mdi-file-search-outline"
            clearable
            height="28"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-file-input
            outlined
            hide-details
            accept="image/*"
            label="Announcement graphic"
            @change="announcementGraphicChanged"
            clearable
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row v-if="expanded">
        <v-col cols="12">
          <v-chip-group
            v-model="filterByTags"
            column
            multiple
            class="ml-4 mr-4"
            @change="selectedFiltersChanged"
          >
            <div
              v-for="(tag, index) in allContentcategories"
              :key="index"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-on="on"
                    v-bind="attrs"
                    large
                    filter
                    outlined
                    medium
                    :color="colorSequence[index % colorSequence.length]"
                    @click="chipClicked(tag)"
                  >
                    {{ tag.tag }}
                  </v-chip>
                </template>
                <span>
                  {{ tag.description }}
                </span>
              </v-tooltip>
            </div>
          </v-chip-group>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                large
                outlined
                @click="categorySelected=null;categoryDialog=true"
                class="ml-4"
              >
                <v-icon>mdi-plus</v-icon>
                <span>New Category</span>
              </v-chip>
            </template>
            <span>
              Create a new Category
            </span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                large
                outlined
                @click="categoryEditMode=!categoryEditMode"
                :color="categoryEditMode ? '#b31616' : null"
                class="ml-2"
              >
                <v-icon>mdi-cog-outline</v-icon>
                <span>Edit Categories</span>
              </v-chip>
            </template>
            <span>
              Edit existing Categories
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          align="center"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                outlined
                icon
                large
                @click="expanded = !expanded"
                class="mb-3 mt-0"
              >
                <v-icon>
                  {{ expanded ? 'mdi-minus' : 'mdi-plus'}}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{
                expanded ? 
                'Hide tag filters' :
                'Show tag filters'
              }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Public</th>
                <th>Description</th>
                <th>Link</th>
                <th>Tags</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(content, index) in allContents"
                :key="index"
              >
                <!-- Name -->
                <td>{{ content.name }}</td>

                <!-- Public -->
                <td>
                  <v-icon :color="content.public ? '#43eb34' : '#b31616'">
                    {{ content.public ? "mdi-check" : "mdi-close"}}
                  </v-icon>
                </td>

                <!-- Description -->
                <td>{{ content.description }}</td>

                <!-- Link -->
                <td>
                  <a
                    target="blank"
                    :href="getLink(content)"
                  >
                    {{ getLink(content) }}
                  </a>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="copyToClipboard(index)"
                      >
                        <v-icon class="ml-1">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy To Clipboard</span>
                  </v-tooltip>
                  <input
                    type="hidden"
                    :id="'copy' + index"
                    :value="getLink(content)"
                  >
                </td>

                <!-- Tags -->
                <td>
                  <v-chip
                    outlined
                    v-for="(contentcategory, index) in contentcategoriesByContentid(content.contentid)"
                    :key="index"
                  >
                    <span>{{ contentcategory }}</span>
                  </v-chip>
                </td>

                <!-- Actions -->
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="deleteContent(content)"
                      >
                        <v-icon>mdi-close-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="isPreviewableContent(content)"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="previewSource=getLink(content);previewPublic=content.public;previewDialog=true"
                      >
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                </td>

              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!--
    <v-card-actions>
      <v-btn
        outlined
        @click="debug"
      >Debug</v-btn>
    </v-card-actions>
    -->

    <category-dialog
      v-if="categoryDialog"
      v-model="categoryDialog"
      :category="categorySelected"
      @done="categoryDialog=false"
    ></category-dialog>

    <preview-dialog
      v-if="previewDialog"
      v-model="previewDialog"
      :src="previewSource"
      :public="previewPublic"
    ></preview-dialog>

  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import previewDialog from "../../components/upload_preview_dialog.vue";
import categoryDialog from "../../components/upload_category_dialog.vue";
import menuTitle from "../../components/helpers/menu_title.vue";
export default {
  name: "Upload",
  components: {
    menuTitle,
    categoryDialog,
    previewDialog,
  },
  computed: {
    ...mapGetters({
      allContentcategories: "allContentcategories",
      allContents: "allContents",
      contenthascategoriesByContentid: "contenthascategoriesByContentid",
      contentcategoryById: "contentcategoryById",
    }),
  },
  props: {},
  data: () => ({
    search: "",
    previewDialog: false,
    previewSource: "",
    previewPublic: false,
    expanded: false,
    categoryEditMode: false,
    categoryDialog: false,
    categorySelected: null,
    filterByTags: [],
    tmp_filterByTags: [],
    uploadDialog: false,
    colorSequence: [
      "#ff2b2b",
      "#ff5d2b",
      "#ff992b",
      "#ffd52b",
      "#d1ff2b",
      "#6bff2b",
      "#2bff6e",
      "#2bffd5",
      "#2bd1ff",
      "#2b80ff",
      "#522bff",
      "#a32bff",
      "#df2bff",
      "#ff2b84",
      "#ff2b2b",
    ],
  }),
  methods: {
    ...mapActions({
      fetchContentsAsync: "fetchContentsAsync",
      fetchContentcategoriesAsync: "fetchContentcategoriesAsync",
      fetchContenthascategoriesAsync: "fetchContenthascategoriesAsync",
      showSnackbar: "showSnackbar",
    }),
    isPreviewableContent: function (content) {
      return ["image/jpeg", "image/png"].includes(content.type);
    },
    contentcategoriesByContentid: function (contentid) {
      var tags = [];
      var contentHasCategories =
        this.contenthascategoriesByContentid(contentid);
      contentHasCategories.forEach((chc) => {
        var contentCategory = contentcategoryById(chc.contentcategoryid);
        tags.push(contentCategory.tag);
      });
      tags.push("COBI.WMS");
      return tags;
    },
    getLink: function (content) {
      // TODO: Don't leave this hardcoded
      var str = "https://localhost:3128";
      str += content.public ? "/publicstatic/" : "/privatestatic/";
      str += encodeURIComponent(content.filename);
      return str;
    },
    copyToClipboard: function (index) {
      let id = "#copy" + index;
      let toCopy = document.querySelector(id);
      toCopy.setAttribute("type", "text");
      toCopy.select();

      try {
        var succ = document.execCommand("copy");
      } catch (exception) {}

      toCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    debug: function () {
      console.log(this.filterByTags);
      console.log(this.allContentcategories);
    },
    selectedFiltersChanged: function () {
      if (this.categoryEditMode) {
        this.filterByTags = this.tmp_filterByTags;
      }
    },
    chipClicked: function (category) {
      // Create a copy of filterByTags as this handler is called
      // before the chip group handler is
      this.tmp_filterByTags = JSON.parse(JSON.stringify(this.filterByTags));

      if (this.categoryEditMode) {
        this.categorySelected = category;
        this.categoryDialog = true;
      } else {
        this.categorySelected = null;
      }
    },
    announcementGraphicChanged: function (e) {
      console.log("Changed announcement graphic");
      console.log(e);
    },
  },
  async mounted() {
    await this.fetchContentsAsync();
    await this.fetchContentcategoriesAsync();
    await this.fetchContenthascategoriesAsync();
  },
};
</script>
<style scoped>
</style>