<template>
  <v-dialog
    v-model="dialog"
    max-width="1300"
    persistent
  >
    <v-card>
      <v-card-title>
        <menu-title :title="category ? 'Edit category' : 'New category'"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-text-field
              outlined
              hide-details
              label="Id"
              v-model="tmpCategory.id"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field
              outlined
              label="Tag"
              hint="A short string to represent this category"
              v-model="tmpCategory.tag"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Name"
              hint="The name of this category"
              v-model="tmpCategory.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Description"
              hint="A more detailed description of this category"
              v-model="tmpCategory.description"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          @click="$emit('done')"
        >Cancel</v-btn>
        <v-btn
          v-if="category"
          outlined
          @click="acceptDialog=true"
        >Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="category ? patch() : post()"
        >
          <span> {{ category ? "Save" : "Add" }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <accept-dialog
      v-model="acceptDialog"
      @accepted="remove()"
    ></accept-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "./helpers/menu_title.vue";
import acceptDialog from "./helpers/generic_accept_dialog.vue";
export default {
  name: "categoryDialog",
  props: {
    value: Boolean,
    category: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    menuTitle,
    acceptDialog,
  },
  computed: {
    ...mapGetters({
      newContentcategory: "newContentcategory",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    acceptDialog: false,
    tmpCategory: {},
  }),
  methods: {
    ...mapActions({
      fetchContentcategoriesAsync: "fetchContentcategoriesAsync",
      postContentcategory: "postContentcategory",
      patchContentcategory: "patchContentcategory",
      deleteContentcategory: "deleteContentcategory",
      showSnackbar: "showSnackbar",
    }),
    debug: function () {
      console.log("category:");
      console.log(this.category);
      console.log("tmpCategory:");
      console.log(this.tmpCategory);
    },
    post: function () {
      this.postContentcategory(this.tmpCategory).then((error) => {
        if (error) {
          this.showSnackbar(
            "Content category could not be added. Error: " + error
          );
        } else {
          this.showSnackbar("Content category has been added.");
          this.$emit("done");
        }
      });
    },
    patch: function () {
      this.patchContentcategory(this.tmpCategory).then((error) => {
        if (error) {
          this.showSnackbar(
            "Content category could not be updated. Error: " + error
          );
        } else {
          this.showSnackbar("Content category has been updated");
          this.$emit("done");
        }
      });
    },
    remove: function () {
      this.deleteContentcategory(this.tmpCategory).then((error) => {
        if (error) {
          this.showSnackbar(
            "Content category could not be deleted. Error: " + error
          );
        } else {
          this.showSnackbar("Content category has been deleted.");
          this.$emit("done");
        }
      });
    },
  },
  async mounted() {
    await this.fetchContentcategoriesAsync();
    if (this.category) {
      this.tmpCategory = JSON.parse(JSON.stringify(this.category));
    } else {
      this.tmpCategory = JSON.parse(JSON.stringify(this.newContentcategory));
    }
  },
};
</script>

<style>
</style>