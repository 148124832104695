<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-img
        contain
        id="imagecontainer"
        :src="getSrc()"
      ></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "previewDialog",
  props: {
    value: Boolean,
    src: String,
    public: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getSrc: function () {
      if (this.public) return this.src;
      return this.src + "?token=" + localStorage.getItem("jwt");
    },
  },
};
</script>

<style>
</style>